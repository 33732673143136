import React, { useEffect } from 'react'
import theme from '../../styles/theme'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons'
import ReactTooltip from 'react-tooltip'

const Tooltip = ({ message }) => {
  useEffect(() => {
    ReactTooltip.rebuild()
  }, [])
  return (
    <FontAwesomeIcon style={{color: theme.colors.mono.greyDarker}} icon={faCircleInfo} data-tip={message}/>
  )
}

export default Tooltip