import React, { useState, useEffect } from 'react'
import { Button } from '../../commons/Button'
import { useCart } from '../../context/CartContext'
import formatMoney from '../../util/formatMoney'
import { IMAGE_URL } from '../../constants'
import { useAuth } from '../../context/AuthContext'
import * as S from './styles'
import { Link } from 'react-router-dom'
import { ProductCartQuantity } from '../ProductCartQuantity'
import { verifyAdvancedPlan } from '../../util/verifyAdvancedPlan'

const ProductCard = ({ product }) => {
  const { addProduct, cart } = useCart()
  const { verifyAcessWithoutLogin } = useAuth()
  const accessWithoutLogin = verifyAcessWithoutLogin()
  const customer = JSON.parse(localStorage.getItem('DAuth:customer'))
  const showProductPromotionInPortal = customer?.environmentConfiguration?.showProductPromotionInPortal
  const findProductOnCart = cart?.items?.filter(item => item.productId === product.id)
  const [qty, setQty] = useState(0)
  const [inicialQty, setInicialQty] = useState(0)

  const hasAdvancedPlan = verifyAdvancedPlan(customer)
  let firstImage = hasAdvancedPlan ? '/assets/images/gray-empty-image.svg' : '/logo.png'
  if (product.images && product.images.length > 0) {
    if (product.images[0].includes('http')) { firstImage = product.images[0] } else { firstImage = `${IMAGE_URL}${product.images[0]}` }
  }

  useEffect(() => {
    if (findProductOnCart?.length > 0) {
      const tmpQty = findProductOnCart?.reduce((total, item) => total + (item.quantity || 0), 0);
      const tmpInitialQty = tmpQty && tmpQty > 0 ? tmpQty : 0;
      setInicialQty(tmpInitialQty)
    } else {
      setInicialQty(0)
    }
  }, [product, findProductOnCart])

  useEffect(() => {
   setQty(inicialQty)
  }, [inicialQty])

  if (product.hasGrade || (product.stockRestrition && product.warehouseQuantity <= 0)) {
    return (
      <S.ProductCard>
        <S.ImageWrapper>
          { inicialQty > 0 && <ProductCartQuantity qtd={inicialQty} moreProductsCarrossel={true}/> }
          <img
            src={firstImage}
            alt={product.description}
          />
        </S.ImageWrapper>
        <S.Title>
          {product.description}
          {product.multiplicationFactor > 1 ? <S.SubTitle> (x{product.multiplicationFactor})</S.SubTitle> : <></>}
        </S.Title>
        {
          accessWithoutLogin === true ?
            <>
              <Link to={`/product/${product.id}`}>
                <Button style={{marginTop: '2rem', padding: '1.5rem 5rem' }}>
                  Ver detalhes
                </Button>
              </Link>
            </> 
          : showProductPromotionInPortal && product.originalPrice > product.price ?
            <>
              <S.OriginalPriceWrapper>
                <S.OriginalPrice>R$ {formatMoney(product.originalPrice, false)}</S.OriginalPrice>
              </S.OriginalPriceWrapper>
              <S.NewPriceWrapper>
                por R$
                <S.NewPrice>{formatMoney(product.price, false)}</S.NewPrice>
              </S.NewPriceWrapper>
            </>
            :
            <>
              <S.NewPriceWrapper>
                por R$
                <S.NewPrice>{formatMoney(product.price, false)}</S.NewPrice>
              </S.NewPriceWrapper>
            </>
        }
       { accessWithoutLogin === false && <S.ProductCardLink to={`/product/${product.id}`}>Ver detalhes</S.ProductCardLink>}
      </S.ProductCard>
    )
  } else {
    return (
      <S.ProductCard>
        <S.ImageWrapper>
          { inicialQty > 0 && <ProductCartQuantity qtd={inicialQty} moreProductsCarrossel={true}/> }
          <img
            src={firstImage}
            alt={product.description}
          />
        </S.ImageWrapper>
        <S.Title>
          {product.description}
          {product.multiplicationFactor > 1 ? <S.SubTitle> (x{product.multiplicationFactor})</S.SubTitle> : <></>}
        </S.Title>
        {
          accessWithoutLogin === true 
          ? <>
              <Link to={`/product/${product.id}`}>
                <Button style={{marginTop: '2rem', padding: '1.5rem 5rem' }}>
                  Ver detalhes
                </Button>
              </Link>
            </> 
          : showProductPromotionInPortal && product.originalPrice > product.price ?
            <>
              <S.OriginalPriceWrapper>
                <S.OriginalPrice>R$ {formatMoney(product.originalPrice, false)}</S.OriginalPrice>
              </S.OriginalPriceWrapper>
              <S.NewPriceWrapper>
                por R$
                <S.NewPrice>{formatMoney(product.price, false)}</S.NewPrice>
              </S.NewPriceWrapper>
              <Button onClick={() => addProduct(product.id, (qty > 0 ? qty + 1 : 1))}>
                Adicionar ao pedido
              </Button>
              <S.MoreDetails to={`/product/${product.id}`}>Ver detalhes</S.MoreDetails>
            </>
            :
            <>
              <S.NewPriceWrapper>
                por R$
                <S.NewPrice>{formatMoney(product.price, false)}</S.NewPrice>
              </S.NewPriceWrapper>
              <Button onClick={() => addProduct(product.id, (qty > 0 ? qty + 1 : 1))}>
                Adicionar ao pedido
              </Button>
              <S.MoreDetails to={`/product/${product.id}`}>Ver detalhes</S.MoreDetails>
            </>
        }
      </S.ProductCard>
    )
  }
}

export default ProductCard
