import styled from 'styled-components/macro'
import Icon from '../../commons/Icon'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Tab, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

export const FinancialContainer = styled.div`
  width: 100%;
  margin-bottom: 6.2rem;
`

export const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
  @media ${({ theme }) => theme.screens.md} {
    margin-right: 0.5rem;
    font-size: 1.5rem;
  }
`

export const FinancialIcon = styled(Icon)`
  width: 2rem;
  height: 2rem;
`

export const FinancialHeader = styled.div`
  display: flex;
  align-items: center;
  margin-top: 6.3rem;
  margin-bottom: 2rem;

  @media ${({ theme }) => theme.screens.md} {
    flex-direction: column;
  }
`

export const FinancialTitle = styled.h2`
  margin-right: 2rem;
  font-size: 3.2rem;
  line-height: 3.8rem;
  letter-spacing: -0.1rem;
`

export const FilterSelectWrapper = styled.div`
  width: 200px;
  margin-left: auto;
  @media ${({ theme }) => theme.screens.md} {
    width: 100%;
    margin: 0;
  }
`

export const FinancialNoContent = styled.span`
  display: flex;
  justify-content: center;
  padding: 2rem;
  color: ${({ theme }) => theme.colors.mono.black};
`

export const FinancialTable = styled.div`
  margin: 2rem 0;
  border-radius: 0.5rem;
  overflow: hidden;
  border: 1px solid ${({ theme }) => theme.colors.mono.grey};
  @media ${({ theme }) => theme.screens.md} {
    display: none;
  }
`

export const FinancialTableMobile = styled.div`
  margin-top: 2rem;
  overflow: hidden;
  border: 1px solid ${({ theme }) => theme.colors.mono.grey};
  display: none;
  @media ${({ theme }) => theme.screens.md} {
    display: block;
    border: none;
  }
`

export const FinancialTableMobileContent = styled.div`
  margin-bottom: 2rem;
`

export const FinancialTableItemMobileFull = styled.div`
  display: flex;
  justify-content: center;
  gap: 2rem;
  font-size: 1.6rem;
  line-height: 2.2rem;
  letter-spacing: -0.02rem;
  padding: 0.65rem 2.2rem;
  border: 1px solid ${({ theme }) => theme.colors.mono.grey};
  border-left: 0;
  border-bottom: 0;
`

export const FinancialTableItem = styled.div`
  display: flex;
  justify-content: space-around;
  gap: 2rem;
  font-size: 1.6rem;
  line-height: 2.2rem;
  letter-spacing: -0.02rem;
  padding: 1.5rem;
  border: 1px solid ${({ theme }) => theme.colors.mono.grey};
  border-left: 0;
  border-top: 0;
`

export const FinancialTableItemLink = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-weight: 700;
  font-size: 1.3rem;
  line-height: 1.7rem;
  color: ${({ theme, disabled }) => disabled ? 'rgba(16, 16, 16, 0.3)' : theme.colors.orange.default};

  &:hover {
    color: ${({ theme, disabled }) => !disabled && theme.colors.mono.black};
  }

  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};

  ${FinancialIcon} {
    margin-right: 0.8rem;
  }
`

export const FinancialTableItemLinkId = styled(FinancialTableItemLink)`
  font-size: 1.6rem;
  line-height: 2.2rem;
  letter-spacing: -0.02rem;
`

export const FinancialTableRow = styled.div`
  display: flex;
  justify-content: center;
  background: ${({ theme }) => theme.colors.mono.white};
  transition: background 0.3s;
  @media ${({ theme }) => theme.screens.md} {
    flex-wrap: wrap;
    width: 100%;
  }
  &:hover {
    background: #fafafa;
  }

  &:last-of-type {
    ${FinancialTableItem} {
      border-bottom: 0;
    }
  }

  ${FinancialTableItemMobileFull} {
    @media ${({ theme }) => theme.screens.md} {
      width: 100%;
      border-left: 1px solid ${({ theme }) => theme.colors.mono.grey};
    }
  }

  ${FinancialTableItem} {
    text-align: center;
    @media ${({ theme }) => theme.screens.md} {
      border-top: 1px solid ${({ theme }) => theme.colors.mono.grey};
      border-bottom: 0;
    }

    &:nth-of-type(1) {
      width: 14%;
      @media ${({ theme }) => theme.screens.md} {
        width: 50%;
        border-left: 1px solid ${({ theme }) => theme.colors.mono.grey};
      }
    }

    &:nth-of-type(2) {
      width: 14%;
      @media ${({ theme }) => theme.screens.md} {
        width: 50%;
        border-right: 1px solid ${({ theme }) => theme.colors.mono.grey} !important;
      }
    }

    &:nth-of-type(3) {
      flex-grow: 1;
      justify-content: flex-start;
      @media ${({ theme }) => theme.screens.md} {
        width: 100%;
        display: flex;
        justify-content: space-around;
        border: 1px solid ${({ theme }) => theme.colors.mono.grey} !important;
        margin-bottom: 2rem;
      }
    }

    &:nth-of-type(4) {
      width: 14%;
    }

    &:nth-of-type(5) {
      width: 14%;
    }

    &:nth-of-type(6) {
      width: 7.5%;
    }

    &:nth-of-type(7) {
      width: 7.5%;
    }

    &:last-of-type {
      border-right: 0;
    }
  }
`

export const FinancialTableHeader = styled(FinancialTableRow)`
  background: ${({ theme }) => theme.colors.mono.greyLight};
  &:hover {
    background: ${({ theme }) => theme.colors.mono.greyLight};
  }

  ${FinancialTableItem} {
    padding: 0.65rem 2.2rem;

    &:nth-of-type(6) {
      width: 15%;
    }
  }
`

export const FinancialButton = styled.button`
  display: flex;
  align-items: center;
  &:hover:not([disabled]) {
    color: ${({ theme }) => theme.colors.orange.default};
  }

  @media ${({ theme }) => theme.screens.md} {
    color: ${({ theme, disabled }) => disabled ? 'rgba(16, 16, 16, 0.3)' : theme.colors.orange.default};
    font-weight: 700;
    font-size: 1.3rem;
    line-height: 1.7rem;
  }
`

export const CustomTabList = styled(TabList)`
  border-bottom: 1px solid ${({ theme }) => theme.colors.orange.default};
  padding: 0;
  margin: 0;
  display: flex;
  width:99.5%
`;

export const CustomTab = styled(Tab)`
  list-style: none;
  padding: 10px 20px;
  cursor: pointer;
  border: 1px solid transparent;
  color: ${({ theme }) => theme.colors.mono.black};
  position: relative;
  bottom: -1px;
  outline: none;

  &.react-tabs__tab--selected {
    border-color: ${({ theme }) => theme.colors.orange.default};
    border-bottom: none;
    font-weight: bold;
    color: ${({ theme }) => theme.colors.orange.default};]
  }

  &:hover {
    color: ${({ theme }) => theme.colors.orange.default};
  }
`;

export const CustomTabPanel = styled(TabPanel)`
  padding: 2rem;
`;

export const NFTable = styled.div`
  margin: 2rem 0;
  border-radius: 0.5rem;
  overflow: hidden;
  border: 1px solid ${({ theme }) => theme.colors.mono.grey};
  @media ${({ theme }) => theme.screens.md} {
    display: none;
  }
`

export const NFTableMobile = styled.div`
  margin-top: 2rem;
  overflow: hidden;
  border: 1px solid ${({ theme }) => theme.colors.mono.grey};
  display: none;
  @media ${({ theme }) => theme.screens.md} {
    display: block;
    border: none;
  }
`

export const NFTableMobileContent = styled.div`
  margin-bottom: 2rem;
`

export const NFTableItemMobileFull = styled.div`
  display: flex;
  justify-content: center;
  gap: 1rem;
  font-size: 1.6rem;
  line-height: 2.2rem;
  letter-spacing: -0.02rem;
  padding: 0.65rem 2.2rem;
  border: 1px solid ${({ theme }) => theme.colors.mono.grey};
  border-left: 0;
  border-bottom: 0;
  word-break: break-word;
`

export const NFTableItem = styled.div`
  display: flex;
  justify-content: center;
  gap: 1rem;
  font-size: 1.6rem;
  line-height: 2.2rem;
  letter-spacing: -0.02rem;
  padding: 1.5rem;
  border: 1px solid ${({ theme }) => theme.colors.mono.grey};
  border-left: 0;
  border-top: 0;
  word-break: break-word;
`

export const NFTableItemAccessKey = styled(NFTableItem)`
  &:hover {
    cursor: pointer;
  }
`

export const NFTableItemLink = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-weight: 700;
  font-size: 1.3rem;
  line-height: 1.7rem;
  color: ${({ theme, disabled }) => disabled ? 'rgba(16, 16, 16, 0.3)' : theme.colors.orange.default};

  &:hover {
    color: ${({ theme, disabled }) => !disabled && theme.colors.mono.black};
  }

  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};

  ${FinancialIcon} {
    margin-right: 0.8rem;
  }
`

export const NFTableItemLinkId = styled(NFTableItemLink)`
  font-size: 1.6rem;
  line-height: 2.2rem;
  letter-spacing: -0.02rem;
`

export const NFTableRow = styled.div`
  display: flex;
  justify-content: center;
  background: ${({ theme }) => theme.colors.mono.white};
  transition: background 0.3s;
  @media ${({ theme }) => theme.screens.md} {
    flex-wrap: wrap;
    width: 100%;
  }
  &:hover {
    background: #fafafa;
  }

  &:last-of-type {
    ${NFTableItem} {
      border-bottom: 0;
    }
  }

  ${NFTableItemMobileFull} {
    @media ${({ theme }) => theme.screens.md} {
      width: 100%;
      border-left: 1px solid ${({ theme }) => theme.colors.mono.grey};
    }
  }

  ${NFTableItem} {
    @media ${({ theme }) => theme.screens.md} {
      border-top: 1px solid ${({ theme }) => theme.colors.mono.grey};
      border-bottom: 0;
    }

    &:nth-of-type(1) {
      width: 14%;
      @media ${({ theme }) => theme.screens.md} {
        width: 50%;
        border-left: 1px solid ${({ theme }) => theme.colors.mono.grey};
      }
    }

    &:nth-of-type(2) {
      width: 14%;
      @media ${({ theme }) => theme.screens.md} {
        width: 50%;
        border-right: 1px solid ${({ theme }) => theme.colors.mono.grey} !important;
      }
    }

    &:nth-of-type(3) {
      width: 14%;
      @media ${({ theme }) => theme.screens.md} {
        width: 100%;
        display: flex;
        justify-content: space-around;
        border: 1px solid ${({ theme }) => theme.colors.mono.grey} !important;
        margin-bottom: 2rem;
      }
    }

    &:nth-of-type(4) {
      width: 43%
    }

    &:nth-of-type(5) {
      width: 7.5%;
    }

    &:nth-of-type(6) {
      width: 7.5%;
      
    }

    &:last-of-type {
      border-right: 0;
      
    }
  }
`

export const NFTableHeader = styled(NFTableRow)`
  background: ${({ theme }) => theme.colors.mono.greyLight};
  &:hover {
    background: ${({ theme }) => theme.colors.mono.greyLight};
  }

  ${NFTableItem} {
    padding: 0.65rem 2.2rem;

    &:nth-of-type(5) {
      width: 15%;
    }
  }
`