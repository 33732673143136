import { useState, useEffect } from 'react'
import { useTitle } from '../../hooks/useTitle'
import api from '../../services/api'
import formatMoney from '../../util/formatMoney'
import getDateStr from '../../util/getDateStr'
import * as S from './styles'
import Breadcrumb from '../../components/Breadcrumb'
import LoadingBackground from '../../components/LoadingBackground'
import { toast } from 'react-toastify'
import ReactTooltip from 'react-tooltip'
import { useAuth } from '../../context/AuthContext'
import Paginator from '../../components/Paginator'
import { faLink } from '@fortawesome/free-solid-svg-icons'
import { faArrowAltCircleDown} from '@fortawesome/free-regular-svg-icons'
import SearchableSelect from 'react-select'
import { themeForSearchableSelect } from '../../util/themeForSearchableSelect'
import { Tabs } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import Tooltip from '../../commons/Tooltip'

const Financial = () => {
  const { user } = useAuth()
  const [isLoading, setIsLoading] = useState(false)
  const [nfs, setNfs] = useState([])
  const [accounts, setAccounts] = useState([])
  const [accountStatusOptions, setAccountStatusOptions] = useState([])
  const [accountsCurrentPage, setAccountsCurrentPage] = useState(1)
  const [accountsTotalPages, setAccountsTotalPages] = useState(0)
  const [nfsCurrentPage, setNfsCurrentPage] = useState(1)
  const [nfsTotalPages, setNfsTotalPages] = useState(0)
  const [copiedRowId, setCopiedRowId] = useState(null);
  const [filteredStatus, setFilteredStatus] = useState(null);
  const [filteredStatusValue, setFilteredStatusValue] = useState(null);
  const [noAccountMessage, setNoAccountMessage] = useState(null);
  const [selectedTab, setSelectedTab] = useState(0);

  const getFinancialInformation = async (status) => {
    setIsLoading(true)
    try {
      const checkStatus = status ? `&status=${status}` : '';
      const response = await api.get(`api/v2/AccountReceivable?ClientId=${user?.clientId}&Ecommerce=true&Page=${accountsCurrentPage}&Size=10${checkStatus}`);
      const responseData = response?.data?.response?.data;

      if (status && responseData?.length === 0) {
        setNoAccountMessage(`Não há registros com o status ${status}`);
      } else {
        setNoAccountMessage('Você não possui histórico financeiro para visualização.');
      }

      setAccounts(responseData);
      setAccountsTotalPages(response?.data?.response?.totalPages);
    } catch (error) {
      console.log('error:', error);
      toast.error('Falha ao obter Informações Financeiras.')
    } finally {
      setIsLoading(false)
      ReactTooltip.rebuild()
    }
  }

  const getAccountStatus = async () => {
    setIsLoading(true)
    try {
      const response = await api.get(`api/v2/AccountReceivable/Status`);
      const responseData = response?.data?.response?.data;
 
      const tmpAccountStatusOptions = responseData?.length > 0
      ? [
          { value: '', label: '' },
          ...responseData.map(account => ({
            value: account,
            label: account,
          })),
        ]
      : [];

      setAccountStatusOptions(tmpAccountStatusOptions);
    } catch (error) {
      console.log('error:', error);
      toast.error('Falha ao obter Status das Informações Financeiras.')
    } finally {
      setIsLoading(false)
    }
  }

  const getNFs = async () => {
    setIsLoading(true);
    try {
      const response = await api.get(`api/v2/ImportedNfe?ClientId=${user?.clientId}&Page=${nfsCurrentPage}&Size=10`);
      const responseData = response?.data?.response?.data;
      setNfs(responseData);
      setNfsTotalPages(response?.data?.response?.totalPages);
    
    } catch (error) {
      console.log('error:', error);
      toast.error('Falha ao obter Notas Fiscais.')
    } finally {
      setIsLoading(false)
    }
  }

  const handlePageClick = (e, tab) => {
    window.scrollTo({ top: 0 })
    if (tab === 'accounts') {
      setAccountsCurrentPage(e.selected + 1)
    } else {
      setNfsCurrentPage(e.selected + 1)
    }
  }

  const handleCopyLink = async (id, link) => {
    try {
      toast.dismiss();
      await navigator.clipboard.writeText(link);
      setCopiedRowId(id);
      toast.success('Link copiado!', {
        autoClose: 1500,
        theme: "light",
      });
    } catch (error) {
      console.error("Falha ao copiar:", error);
      setCopiedRowId(null);
      toast.error('Falha ao copiar link', {
        autoClose: 1500,
        theme: "light",
      });
    } finally {
      setTimeout(() => {
        setCopiedRowId(null);
      }, 3000);
    };
  }

  const handleCopyAccessKey = async (id, key) => {
    if (key) {
      try {
        toast.dismiss();
        await navigator.clipboard.writeText(key);
        setCopiedRowId(id);
        toast.success('Chave de acesso copiada!', {
          autoClose: 1500,
          theme: "light",
        });
      } catch (error) {
        console.error("Falha ao copiar:", error);
        setCopiedRowId(null);
        toast.error('Falha ao copiar chave de acesso', {
          autoClose: 1500,
          theme: "light",
        });
      } finally {
        setTimeout(() => {
          setCopiedRowId(null);
        }, 3000);
      };
    };
  }

  const handleSelectedFilterStatus = (selectedOpt) => {
    const value = selectedOpt.value;
    setFilteredStatusValue(value ? value : null)
    setFilteredStatus(selectedOpt ? selectedOpt : null)
    setAccountsCurrentPage(1)
    getFinancialInformation(value)
  }

  const generateNFLink = (data) => {
    const linkNF = data?.files[0]?.length > 0 
    ? `https://s3.amazonaws.com/bendito.customer.storage/nf/${data.files[0]}` 
    : data.accessKey 
      ? 'https://www.nfe.fazenda.gov.br/portal/consultaRecaptcha.aspx'
      : '';
    return linkNF;
  }

  const handleTabSelect = (index) => {
    setSelectedTab(index); 
  };

  useEffect(() => {
    getAccountStatus()
  }, [])

  useEffect(() => {
    getFinancialInformation(filteredStatusValue)
  }, [accountsCurrentPage])

  useEffect(() => {
    getNFs()
  }, [nfsCurrentPage])

  useTitle('Informações Financeiras')

  return (
    <S.FinancialContainer>
      <Breadcrumb current='Informações Financeiras' />
      {isLoading
        ? ( <LoadingBackground /> )
        : (
          <>
            <S.FinancialHeader>
              <S.FinancialTitle>Informações Financeiras</S.FinancialTitle>
            </S.FinancialHeader>
            <Tabs selectedIndex={selectedTab} onSelect={handleTabSelect}>
              <S.CustomTabList>
                <S.CustomTab>Títulos</S.CustomTab>
                <S.CustomTab>Notas Fiscais</S.CustomTab>
              </S.CustomTabList>
              <S.CustomTabPanel>
                {/* Contas a Receber */}
                <S.FilterSelectWrapper>
                <SearchableSelect
                  name='select-status'
                  value={filteredStatus}
                  placeholder='Filtrar por status...'
                  noOptionsMessage={() => 'Sem resultados.'}
                  defaultValue=''
                  styles={themeForSearchableSelect()}
                  options={accountStatusOptions}
                  onChange={(selectedOption) => handleSelectedFilterStatus(selectedOption)}
                />
                </S.FilterSelectWrapper>
                {/* Tabela Web */}
                <S.FinancialTable>
                  <S.FinancialTableHeader>
                    <S.FinancialTableItem>Data de emissão</S.FinancialTableItem>
                    <S.FinancialTableItem>Data de vencimento</S.FinancialTableItem>
                    <S.FinancialTableItem>Documento</S.FinancialTableItem>
                    <S.FinancialTableItem>Valor</S.FinancialTableItem>
                    <S.FinancialTableItem>Status</S.FinancialTableItem>
                    <S.FinancialTableItem>Visualizar boleto</S.FinancialTableItem>
                  </S.FinancialTableHeader>
                  {accounts.length > 0 ? accounts.map(account => (
                    <S.FinancialTableRow key={account.id}>
                      <S.FinancialTableItem>{getDateStr(account.dataEmissao, true)}</S.FinancialTableItem>
                      <S.FinancialTableItem>{getDateStr(account.dataVencimento, true)}</S.FinancialTableItem>
                      <S.FinancialTableItem>{account.doc}</S.FinancialTableItem>
                      <S.FinancialTableItem>{formatMoney(account.value)}</S.FinancialTableItem>
                      <S.FinancialTableItem>{account.status}</S.FinancialTableItem>
                      <S.FinancialTableItem>
                        <S.FinancialTableItemLink href={account.linkBoleto || '#'} target="_blank" rel="noopener noreferrer" disabled={!account.linkBoleto}>
                          Baixar
                        </S.FinancialTableItemLink>
                      </S.FinancialTableItem>
                      <S.FinancialTableItem>
                        <S.FinancialButton
                          data-tip={
                            account.linkBoleto
                              ? copiedRowId === account.id
                                ? 'Link copiado!'
                                : 'Copiar link'
                              : 'Link indisponível'
                          }
                          onClick={account.linkBoleto ? () => handleCopyLink(account.id, account.linkBoleto) : undefined}
                          disabled={!account.linkBoleto}
                        >
                          <S.StyledFontAwesomeIcon icon={faLink} />
                        </S.FinancialButton>
                      </S.FinancialTableItem>
                    </S.FinancialTableRow>
                  ))
                : <S.FinancialTableRow>
                    <S.FinancialNoContent>
                      {noAccountMessage}
                    </S.FinancialNoContent>
                  </S.FinancialTableRow>}
                </S.FinancialTable>
                {/* Tabela Mobile */}
                <S.FinancialTableMobile>
                  {accounts.length > 0 
                    ? accounts.map(account => (
                      <S.FinancialTableMobileContent key={account.id}>
                        <S.FinancialTableHeader>
                          <S.FinancialTableItemMobileFull>Documento</S.FinancialTableItemMobileFull>
                          <S.FinancialTableRow>
                            <S.FinancialTableItemMobileFull>{account.doc}</S.FinancialTableItemMobileFull>
                          </S.FinancialTableRow>
                        </S.FinancialTableHeader>
                        <S.FinancialTableHeader>
                          <S.FinancialTableItem>Data de emissão</S.FinancialTableItem>
                          <S.FinancialTableItem>Data de vencimento</S.FinancialTableItem>
                          <S.FinancialTableRow>
                            <S.FinancialTableItem>{getDateStr(account.dataEmissao, true)}</S.FinancialTableItem>
                            <S.FinancialTableItem>{getDateStr(account.dataVencimento, true)}</S.FinancialTableItem>
                          </S.FinancialTableRow>
                        </S.FinancialTableHeader>
                        <S.FinancialTableHeader>
                          <S.FinancialTableItem>Valor</S.FinancialTableItem>
                          <S.FinancialTableItem>Status</S.FinancialTableItem>
                          <S.FinancialTableRow>
                            <S.FinancialTableItem>{formatMoney(account.value)}</S.FinancialTableItem>
                            <S.FinancialTableItem>{account.status}</S.FinancialTableItem>
                            <S.FinancialTableItem>
                              <S.FinancialTableItemLink href={account.linkBoleto || '#'} target="_blank" rel="noopener noreferrer" disabled={!account.linkBoleto}>
                              <S.StyledFontAwesomeIcon icon={faArrowAltCircleDown} /> Baixar
                              </S.FinancialTableItemLink>
                              <S.FinancialButton
                                data-tip={
                                  account.linkBoleto
                                    ? copiedRowId === account.id
                                      ? 'Link copiado!'
                                      : 'Copiar link'
                                    : 'Link indisponível'
                                }
                                onClick={account.linkBoleto ? () => handleCopyLink(account.id, account.linkBoleto) : undefined}
                                disabled={!account.linkBoleto}
                              >
                                <S.StyledFontAwesomeIcon icon={faLink} /> Copiar Link
                              </S.FinancialButton>
                            </S.FinancialTableItem>
                          </S.FinancialTableRow>
                        </S.FinancialTableHeader>

                      </S.FinancialTableMobileContent>
                    ))
                    : 
                      <S.FinancialNoContent>
                        {noAccountMessage}
                      </S.FinancialNoContent>
                  }
                </S.FinancialTableMobile>
                {!isLoading && accountsTotalPages > 1 && <Paginator totalPages={accountsTotalPages} currentPage={accountsCurrentPage} handlePageClick={(e) => handlePageClick(e, 'accounts')} />}
              </S.CustomTabPanel>
              <S.CustomTabPanel>
                {/* Contas a Receber */}
                {/* Tabela Web */}
                <S.NFTable>
                  <S.NFTableHeader>
                    <S.NFTableItem>Data de emissão</S.NFTableItem>
                    <S.NFTableItem>Data de registro</S.NFTableItem>
                    <S.NFTableItem>Número</S.NFTableItem>
                    <S.NFTableItem>Chave de acesso <Tooltip message={'Clique na chave de acesso para copiá-la.'}/></S.NFTableItem>
                    <S.NFTableItem>Visualizar NF <Tooltip message={'Para acessar sua NF no site do Portal da NF-e, utilize a chave de acesso.'}/></S.NFTableItem>
                  </S.NFTableHeader>
                  {nfs.length > 0 ? nfs.map(nf => (
                    <S.NFTableRow key={nf.id}>
                      <S.NFTableItem>{getDateStr(nf.dateOfIssue, true)}</S.NFTableItem>
                      <S.NFTableItem>{getDateStr(nf.dateOfRegistry, true)}</S.NFTableItem>
                      <S.NFTableItem>{nf.number}</S.NFTableItem>
                      <S.NFTableItemAccessKey 
                        data-tip={
                          (nf?.accessKey)
                            ? copiedRowId === nf.id
                              ? 'Chave de acesso copiada!'
                              : 'Copiar chave de acesso'
                            : 'Chave de acesso indisponível'
                        } 
                        onClick={() => handleCopyAccessKey(nf.id, nf.accessKey)}
                      >
                        {nf.accessKey}
                      </S.NFTableItemAccessKey>
                      <S.NFTableItem>
                        <S.NFTableItemLink href={generateNFLink(nf)} target="_blank" rel="noopener noreferrer" disabled={!nf?.files[0] && !nf?.accessKey}>
                          Acessar
                        </S.NFTableItemLink>
                      </S.NFTableItem>
                      <S.NFTableItem>
                        <S.FinancialButton
                          data-tip={
                            (nf?.files[0] || nf?.accessKey)
                              ? copiedRowId === nf.id
                                ? 'Link copiado!'
                                : 'Copiar link'
                              : 'Link indisponível'
                          }
                          onClick={(nf?.files[0] || nf?.accessKey) ? () => handleCopyLink(nf.id, generateNFLink(nf)) : undefined}
                          disabled={(!nf?.files[0] && !nf?.accessKey)}
                        >
                          <S.StyledFontAwesomeIcon icon={faLink} />
                        </S.FinancialButton>
                      </S.NFTableItem>
                    </S.NFTableRow>
                  ))
                : <S.NFTableRow>
                    <S.FinancialNoContent>
                      Você não possui notas fiscais para visualização.
                    </S.FinancialNoContent>
                  </S.NFTableRow>}
                </S.NFTable>
                {/* Tabela Mobile */}
                <S.NFTableMobile>
                  {nfs.length > 0 
                    ? nfs.map(nf => (
                      <S.NFTableMobileContent key={nf.id}>
                        <S.NFTableHeader>
                          <S.NFTableItemMobileFull>Chave de acesso <Tooltip message={'Clique na chave de acesso para copiá-la.'}/></S.NFTableItemMobileFull>
                          <S.NFTableRow>
                            <S.NFTableItemMobileFull
                              data-tip={
                                (nf?.accessKey)
                                  ? copiedRowId === nf.id
                                    ? 'Chave de acesso copiada!'
                                    : 'Copiar chave de acesso'
                                  : 'Chave de acesso indisponível'
                              } 
                              onClick={() => handleCopyAccessKey(nf.id, nf.accessKey)}
                            >
                              {nf.accessKey}
                            </S.NFTableItemMobileFull>
                          </S.NFTableRow>
                        </S.NFTableHeader>
                        <S.NFTableHeader>
                          <S.NFTableItemMobileFull>Número</S.NFTableItemMobileFull>
                          <S.NFTableRow>
                            <S.NFTableItemMobileFull>{nf.number}</S.NFTableItemMobileFull>
                          </S.NFTableRow>
                        </S.NFTableHeader>
                        <S.NFTableHeader>
                          <S.NFTableItem>Data de emissão</S.NFTableItem>
                          <S.NFTableItem>Data de registro</S.NFTableItem>
                          <S.NFTableRow>
                            <S.NFTableItem>{getDateStr(nf.dateOfIssue, true)}</S.NFTableItem>
                            <S.NFTableItem>{getDateStr(nf.dateOfRegistry, true)}</S.NFTableItem>
                            <S.NFTableItem>
                              <S.NFTableItemLink href={generateNFLink(nf)} target="_blank" rel="noopener noreferrer" disabled={!nf?.files[0] && !nf?.accessKey}>
                                <S.StyledFontAwesomeIcon icon={faArrowAltCircleDown} /> Acessar
                              </S.NFTableItemLink>
                              <S.FinancialButton
                                data-tip={
                                  (nf?.files[0] || nf?.accessKey)
                                    ? copiedRowId === nf.id
                                      ? 'Link copiado!'
                                      : 'Copiar link'
                                    : 'Link indisponível'
                                }
                                onClick={(nf?.files[0] || nf?.accessKey) ? () => handleCopyLink(nf.id, generateNFLink(nf)) : undefined}
                                disabled={(!nf?.files[0] && !nf?.accessKey)}
                              >
                                <S.StyledFontAwesomeIcon icon={faLink} />
                              </S.FinancialButton>
                            </S.NFTableItem>
                          </S.NFTableRow>
                        </S.NFTableHeader>
                      </S.NFTableMobileContent>
                    ))
                    : 
                      <S.FinancialNoContent>
                        Você não possui notas fiscais para visualização.
                      </S.FinancialNoContent>
                  }
                </S.NFTableMobile>
                {!isLoading && nfsTotalPages > 1 && <Paginator totalPages={nfsTotalPages} currentPage={nfsCurrentPage} handlePageClick={(e) => handlePageClick(e, 'nfs')} />}
              </S.CustomTabPanel>
            </Tabs>
          </>
        )
      }
    </S.FinancialContainer>
  )
}

export default Financial