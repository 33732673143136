import { IMAGE_URL } from "../constants";

export const verifyAdvancedPlan = (customer) => {
  return customer?.plans?.length > 0 
  ? customer?.plans?.some(plan => plan?.plans?.code === "BB2BA")
  : false;
};

export const verifyAdvancedPlanImage = (customer) => {
  const customerBackgroundImage = customer?.environmentConfiguration?.whiteLabelOptions?.image;
  const hasAdvancedPlan = verifyAdvancedPlan(customer);

  return (hasAdvancedPlan && customerBackgroundImage) ? `${IMAGE_URL}${customerBackgroundImage}` : '';
};