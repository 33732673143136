import { useTitle } from '../../hooks/useTitle'
import * as S from './styles'
import { IMAGE_URL } from '../../constants'
import { useState, useEffect } from 'react'
import { verifyAdvancedPlanImage } from '../../util/verifyAdvancedPlan'

const PageNotFound = () => {
  const [backgroundImage, setBackgroundImage] = useState()
  const customerInfo = JSON.parse(localStorage.getItem('customerInfo'))
  const customer = JSON.parse(localStorage.getItem('DAuth:customer'))

  const getPageImage = async () => {
    setBackgroundImage(verifyAdvancedPlanImage(customer))
  }

  useEffect(() => {
    if (customerInfo) {
      getPageImage()
    }
  }, [])

  useTitle('Bendito - Acelere suas vendas!')
  return (
    <S.PageNotFound>
      <S.PageNotFoundContainer>
        <S.PageNotFoundWrapper>
          <S.PageNotFoundImage backgroundImage={backgroundImage}></S.PageNotFoundImage>
          <S.PageNotFoundInner>
            <S.PageNotFoundLogoWrapper>
              <S.PageNotFoundLogo src={!!customerInfo && customerInfo?.logo ? `${IMAGE_URL}${customerInfo.logo}` : '/assets/images/logo-bendito-white.svg'} />
            </S.PageNotFoundLogoWrapper>
            <S.PageNotFoundTitle>Página não encontrada!</S.PageNotFoundTitle>
          </S.PageNotFoundInner>
        </S.PageNotFoundWrapper>
      </S.PageNotFoundContainer>
    </S.PageNotFound>
  )
}

export default PageNotFound
