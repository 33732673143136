import styled from 'styled-components/macro'
import Container from '../../components/Container'

export const PageNotFound = styled.div`
  background-position: 20rem;
  background-color: ${({ theme }) => theme.colors.orange.default};
  height: 100vh;

  @media ${({ theme }) => theme.screens.md} {
    background-image: unset;
    min-height: 100vh;
  }
`

export const PageNotFoundImage = styled.div`
  background-image: ${({ backgroundImage }) => backgroundImage ? `url(${backgroundImage})` : 'url(/assets/images/login-bg-3.png)'};
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 12rem;
  background-color: ${({ theme }) => theme.colors.orange.default};
  height: ${({ backgroundImage }) => backgroundImage?.includes('assets') ? '100vh' : '90%'};
  width: 100%;
  max-width: 100%;

  @media ${({ theme }) => theme.screens.md} {
    background-image: unset;
    min-height: 0%;
    width: 0%;
  }
`

export const PageNotFoundContainer = styled(Container)`
  height: 100%;
`

export const PageNotFoundWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 100%;

  @media ${({ theme }) => theme.screens['2xl']} {
    margin-right: 5%;
  }

  @media ${({ theme }) => theme.screens.xl} {
    margin-right: 0;
  }

  @media ${({ theme }) => theme.screens.md} {
    padding-top: 6rem;
    align-items: unset;
    justify-content: unset;
  }
`

export const PageNotFoundInner = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  max-width: 52rem;
  width: 100%;

  @media ${({ theme }) => theme.screens.md} {
    max-width: unset;
  }
`

export const PageNotFoundLogo = styled.img`
`

export const PageNotFoundLogoWrapper = styled.div`
  display: flex;
  width: 24rem;
  justify-content: center;
  align-items: center;
  @media ${({ theme }) => theme.screens.md} {
    width: 16rem;
  }
`

export const PageNotFoundTitle = styled.h1`
  max-width: 36.7rem;
  width: 100%;
  margin-top: 3rem;
  font-size: 3.2rem;
  line-height: 3.8rem;
  text-align: center;
  letter-spacing: -0.1rem;
  color: ${({ theme }) => theme.colors.mono.white};

  @media ${({ theme }) => theme.screens.lg} {
    font-size: 2.4rem;
    line-height: 2.8rem;
  }
`