import { useEffect, useState } from 'react'
import SearchableSelect from 'react-select'
import { toast } from 'react-toastify'
import BackButton from '../../components/BackButton'
import OrderSuccess from '../../components/Cart/OrderSuccess'
import LoadingBackground from '../../components/LoadingBackground'
import { useAuth } from '../../context/AuthContext'
import { useCart } from '../../context/CartContext'
import { useTitle } from '../../hooks/useTitle'
import api from '../../services/api'
import { customStylesForSearchableSelect } from '../../util/searchableSelectStyle'
import * as S from './styles'

const CartPayment = () => {
  const { cart, clearCart, updateMethodPayment, startSaveCart } = useCart()
  const [isSuccess, setIsSuccess] = useState(false)
  const [notes, setNotes] = useState('')
  const [paymentMethodId, setPaymentMethodId] = useState()
  const [isLoading, setIsLoading] = useState(false)
  const [buttonDisabled, setButtonDisabled] = useState(false)
  const [paymentMethod, setPaymentMethod] = useState([])
  const [selectedPayMethod, setSelectedPayMethod] = useState('')
  const [deadlines, setDeadlines] = useState([])
  const customer = JSON.parse(localStorage.getItem('DAuth:customer'))

  const { user } = useAuth()

  const getPaymentMethod = async () => {
    const response = await api.get('/api/v2/PaymentMethod?OnlyB2B=true')
    setPaymentMethod(response.data.response.data)
  }

  const handleSaveCart = async () => {
    cart.notes = notes
    startSaveCart(cart)
  }

  useEffect(() => {
    if (user) {
      setPaymentMethodId(user.paymentMethod[0].id)
    }
    const getDeadlines = async () => {
      const response = await api.get('/Deadline')
      setDeadlines(response.data)
    }
    if (!deadlines.length) {
      getDeadlines()
    }
    getPaymentMethod()


    const storedPayMethod = JSON.parse(localStorage.getItem('@bendito:selectedPayMethod'))
    if (!!storedPayMethod) {
      if (storedPayMethod.deadline) {
        const hasValidDeadline = deadlines.some(deadline => deadline.id === storedPayMethod.deadline && deadline.status === 1);
        if (!hasValidDeadline) {
          localStorage.removeItem('@bendito:selectedPayMethod');
          return;
        }else{
          setSelectedPayMethod(storedPayMethod)
          setPaymentMethodId(storedPayMethod.value)
        }
      }
    }

    const storedInvoiceNote = JSON.parse(localStorage.getItem('@bendito:invoiceNote'))
    if (!!storedInvoiceNote) {
      setNotes(storedInvoiceNote)
    }    
  }, [])

  useEffect(() => {
    handleSaveCart(cart)
    if (cart?.items.length === 0) {
      setSelectedPayMethod('');
      localStorage.removeItem('@bendito:selectedPayMethod');
    }
  }, [cart])

  const validPaymentMethods = paymentMethod.filter((pay) => {
    if (pay.deadlineId && pay.deadlineId > 0) {
      const hasValidDeadline = deadlines.some(deadline => deadline.id === pay.deadlineId);
      if (!hasValidDeadline) {
        return false;
      }
    }

    if (!pay.status)
      return false;

    const hasValidMinimumPrice = !!pay.minimumPrice && pay.minimumPrice > 0;
    const hasValidMaximumPrice = !!pay.maximumPrice && pay.maximumPrice > 0;

    if (hasValidMinimumPrice || hasValidMaximumPrice) {
      if (hasValidMinimumPrice && hasValidMaximumPrice) {
        // Se tiver preço mínimo E preço máximo, verifica se o valor do pedido está entre os dois
        const isValid = cart?.totalValue >= pay.minimumPrice && cart?.totalValue <= pay.maximumPrice;
        return isValid;
      } else if (hasValidMinimumPrice && !hasValidMaximumPrice) {
        // Se tiver somente preço mínimo, verifica se o valor do pedido está acima do mínimo
        const isValid = cart?.totalValue >= pay.minimumPrice;
        return isValid;
      } else if (!hasValidMinimumPrice && hasValidMaximumPrice) {
        // Se tiver somente preço máximo, verifica se o valor do pedido está abaixo do máximo
        const isValid = cart?.totalValue <= pay.maximumPrice;
        return isValid;
      }
    } else {
        return true;
    }
  })

  const handleSelectChange = (selectedOption) => {
    setPaymentMethodId(selectedOption.value);
    updateMethodPayment(selectedOption);
    setSelectedPayMethod(selectedOption);
    localStorage.setItem('@bendito:selectedPayMethod', JSON.stringify(selectedOption));
  }

  const handleNotesChange = (e) => {
    setNotes(e.target.value);
    localStorage.setItem('@bendito:invoiceNote', JSON.stringify(e.target.value));
  }

  const paymentMethodOptions = validPaymentMethods.map(payMethod => ({
    value: payMethod.id,
    label: payMethod.description,
    deadline: payMethod.deadlineId
  }))

  const CustomToastWithLink = () => (
    <span>Antes de fechar o pedido, é necessário um endereço.<br /><a href='/profile' style={{ textDecoration: 'underline', color: 'white', fontWeight: 'bold' }}>Clique aqui</a> para cadastrar novo endereço.</span>
  )

  const CustomFinancialToastWithLink = () => (
    <span>Você possui contas em atraso.<br /><a href='/financial' style={{ textDecoration: 'underline', color: 'white', fontWeight: 'bold' }}>Clique aqui</a> para visualizar ou entre em contato com o financeiro.</span>
  )

  const newOrder = async () => {
    const responseAdr = await api.get(`api/v2/Client/${user.clientId}/Address`)
    const addressResponse = responseAdr.data.response.data

    if (cart?.items.length === 0) {
      toast.warning('Para efetuar o pedido, adicione produtos ao carrinho.', {
        position: 'top-right',
      })
      setButtonDisabled(false)
      return false
    }

    if (addressResponse.length === 0) {
      toast.error(CustomToastWithLink, {
        autoClose: false,
        position: 'top-right',
      })
      setButtonDisabled(false)
      return false
    }

    setIsLoading(true)

    //Pega por padrão o prazo de pagto disponível para o usuário
    var deadlineIdActive = (() => {
      var activeDeadline = user.deadline?.find(deadline => deadline.status === 1);
      if (activeDeadline != null && user.preferredDeadlineId != 0) {
        activeDeadline = user.deadline?.find(x => x.id === user.preferredDeadlineId && x.status === 1);
      }
      return activeDeadline ? activeDeadline.id : '';
    })()

    //Se a forma de pagto tiver um prazo ativo pega da forma de pagto
    if (cart?.deadline?.typeStatus == "Ativo") {
      deadlineIdActive = cart.deadlineId
    }

    const addInvoiceObj = {
      Advance: cart.advance,
      DeadlineId: deadlineIdActive,
      DeliveryForecast: cart.deliveryForecast,
      Discount: cart.discount,
      Freight: cart.freight,
      Items: cart.items,
      Nature: 2,
      Notes: notes,
      PaymentMethodId: paymentMethodId,
      Price: cart.price,
      Tax: cart.tax,
      TotalValue: cart.totalValue,
      Value: cart.value,
      PoliticsDiscount: cart.politicsDiscount,
      CreationSource: 2
    }

    try {
      const response = await api.post('/Invoice', addInvoiceObj)
      const invoiceId = response.data.data.id
      const cartId = JSON.parse(localStorage.getItem('@bendito:cartId'))

      if (!!cartId) {
        const deleteCart = await api.post(`/api/v2/cart/${cartId}/close`, { invoiceId })
        localStorage.removeItem('@bendito:cartId');
      }

      if (response.data.success) {
        setIsSuccess(true)
        clearCart()
        localStorage.removeItem('@bendito:selectedPayMethod');
        localStorage.removeItem('@bendito:invoiceNote');
      }

      setIsLoading(false)
      setButtonDisabled(false)
      toast(response.data.successMessages[0], {
        type: 'success',
        position: 'top-right',
      })
    } catch (error) {
      setIsLoading(false)
      setButtonDisabled(false)
      const receivedErrorMsg = error?.response?.data?.errorMessages[0];
      const financialNotRegular = receivedErrorMsg?.includes('pendência financeira');
      const errorMsg = financialNotRegular ? CustomFinancialToastWithLink() : receivedErrorMsg;
      toast(errorMsg ? errorMsg : 'Algo deu errado. Tente novamente mais tarde.', {
        type: 'error',
        autoClose: 10000,
        position: 'top-right',
      })
    }
  }

  useTitle(!isSuccess ? 'Fechamento do pedido' : 'Pedido realizado com sucesso')

  return (
    <>
      {isLoading && (
        <LoadingBackground />
      )}
      {(!isSuccess && cart) && (
        <S.CartPayment>
          <S.CartPaymentWrapper>
            <S.CartPaymentTop>
              <BackButton to='/cart' />
              <S.CartPaymentTitle>Fechamento do pedido</S.CartPaymentTitle>
            </S.CartPaymentTop>

            <S.CartPaymentBoxContainer>
              <S.CartPaymentBox>
                <S.CartPaymentBold>Selecione uma forma de pagamento:</S.CartPaymentBold>
                {user && (
                  <S.SelectPayment>
                    <SearchableSelect
                      name='select-product'
                      value={selectedPayMethod}
                      placeholder="Selecione..."
                      onChange={handleSelectChange}
                      styles={customStylesForSearchableSelect}
                      options={paymentMethodOptions}
                      noOptionsMessage={() => 'Sem resultados.'}
                    />
                  </S.SelectPayment>
                )}
              </S.CartPaymentBox>
              <S.CartPaymentBox>
                <S.CartPaymentOrderPrices
                  value={cart.value}
                  tax={cart.tax}
                  freight={cart.freight}
                  discount={cart.politicsDiscount}
                  totalValue={cart.totalValue}
                />
              </S.CartPaymentBox>
              <S.CartPaymentBox>
                <S.CartPaymentDeliveryContainer>
                  <S.CartPaymentDeliveryItem>
                    <S.CartPaymentDeliveryLabel>Prazo de entrega</S.CartPaymentDeliveryLabel>
                    <S.CartPaymentDeliveryValue>{cart.deadline.description}</S.CartPaymentDeliveryValue>
                  </S.CartPaymentDeliveryItem>
                  {cart.deadline.type !== 3 &&
                  <S.CartPaymentDeliveryItem>
                    <S.CartPaymentDeliveryLabel>Previsão de entrega</S.CartPaymentDeliveryLabel>
                    <S.CartPaymentDeliveryValue>{cart.deliveryForecast}</S.CartPaymentDeliveryValue>
                  </S.CartPaymentDeliveryItem>}
                </S.CartPaymentDeliveryContainer>
              </S.CartPaymentBox>
              <S.CartPaymentBox>
                <S.CartPaymentField>
                  <S.CartPaymentLabel>Gostaria de adicionar algum comentário?</S.CartPaymentLabel>
                  <S.CartPaymentTextarea value={notes} onChange={handleNotesChange} onBlur={handleSaveCart}/>
                </S.CartPaymentField>
              </S.CartPaymentBox>
            </S.CartPaymentBoxContainer>
            {customer.b2BMessagesCart && customer.b2BMessagesCart != "<p><br></p><p></p>" && (
              <S.CartPaymentDisclaimer>
                <strong>Atenção:</strong>
                <span dangerouslySetInnerHTML={{ __html: customer.b2BMessagesCart }} />
              </S.CartPaymentDisclaimer>
            )}
            <S.CartPaymentButton disabled={buttonDisabled} onClick={()=>{
              setButtonDisabled(true);
              newOrder();
            }}>
              Finalizar meu pedido agora
            </S.CartPaymentButton>
          </S.CartPaymentWrapper>
        </S.CartPayment>
      )}
      {isSuccess && (
        <OrderSuccess />
      )}
    </>
  )
}

export default CartPayment