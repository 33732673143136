import { useState, useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { useTitle } from '../../hooks/useTitle'
import { toast } from 'react-toastify'
import api from '../../services/api'
import * as S from './styles'
import Select from '../../components/Select'
import { IMAGE_URL } from '../../constants'
import { useAuth } from '../../context/AuthContext'
import LoadingBackground from '../../components/LoadingBackground'
import { verifyAdvancedPlanImage } from '../../util/verifyAdvancedPlan'

const Invite = () => {
  const { hash } = useParams()
  const [isLoading, setIsLoading] = useState(false)
  const [isCPF, setIsCPF] = useState(false)
  const [registerDone, setRegisterDone] = useState(false)
  const [backgroundImage, setBackgroundImage] = useState()
  const navigate = useNavigate()
  /* eslint-disable no-unused-vars */
  const { register, handleSubmit, formState: { errors } } = useForm()
  const customerInfo = JSON.parse(localStorage.getItem('customerInfo'))
  const customer = JSON.parse(localStorage.getItem('DAuth:customer'))

  const getPageImage = async () => {
    setBackgroundImage(verifyAdvancedPlanImage(customer))
  }

  useEffect(() => {
    if (customerInfo) {
      getPageImage()
    }
  }, [])

  useEffect(() => {
    if (registerDone) {
      setTimeout(() => {
        navigate('/login')
      }, 2000)
    }
  }, [registerDone, navigate])

  const handleInvite = async data => {
    const { type, code, name, mail } = data
    setIsLoading(true)

    const id = toast.loading('Efetuando seu cadastro...')

    const json = {
      Type: type,
      Code: code,
      Name: name,
      Mail: mail,
    }

    const response = await api.post(`/Customer/AcceptInvite/${encodeURIComponent(hash)}`, json)
    if (response.data.success) {
      toast.update(id, {
        render: response.data.successMessages[0],
        type: 'success',
        isLoading: false,
        delay: 750,
        autoClose: 2000,
      })

      setRegisterDone(true)
    } else {
      toast.update(id, {
        render: response.data.errorMessages[0],
        type: 'error',
        isLoading: false,
        delay: 750,
        autoClose: 5000,
      })
    }

    setIsLoading(false)
  }

  const handleInviteError = (errors, e) => {
    Object.entries(errors).forEach(entry => {
      toast.warning(entry[1].message)
    })
  }

  const handleChangeCharacteristic = (value) => {
    if (value === 'F') { setIsCPF(true) } else { setIsCPF(false) }
  }

  useTitle('Bendito - Acelere suas vendas!')

  return (
    <S.Invite backgroundImage={backgroundImage}>
      <S.InviteContainer>
        <S.InviteWrapper>
          <S.InviteImage backgroundImage={backgroundImage}></S.InviteImage>
          <S.InviteInner>
            <S.InviteLogoWrapper>
              <S.InviteLogo src={customerInfo?.logo ? `${IMAGE_URL}${customerInfo.logo}` : '/assets/images/logo-bendito-white.svg'} />
            </S.InviteLogoWrapper>
            <S.InviteTitle>Vamos fazer o seu cadastro?</S.InviteTitle>
            {/* <S.InviteText>Faça seu cadastro e aproveite!</S.InviteText> */}
            <S.InviteBox>
              <form onSubmit={handleSubmit(handleInvite, handleInviteError)}>
                <S.HomeField>
                  <S.HomeFieldFixLabel>
                    Caracterísica da operação:
                  </S.HomeFieldFixLabel>
                  <Select
                    name='type'
                    register={register}
                    required
                    message='Característica da operação é obrigatório'
                    onChange={e => handleChangeCharacteristic(e.target.value)}
                  >
                    <option value='J'>Jurídica</option>
                    <option value='F'>Física</option>
                  </Select>
                </S.HomeField>
                <S.HomeField>
                  <S.HomeFieldInput
                    type='text'
                    placeholder='&nbsp;'
                    {...register('code', { required: isCPF === true ? 'CPF é obrigatório' : 'CNPJ é obrigatório' })}
                    pattern='[0-9]+'
                    title='Apenas números'
                  />
                  <S.HomeFieldLabel>
                    {isCPF === true ? 'Seu CPF:' : 'Seu CNPJ:'}
                  </S.HomeFieldLabel>
                </S.HomeField>
                <S.HomeField>
                  <S.HomeFieldInput
                    type='text'
                    placeholder='&nbsp;'
                    {...register('name', { required: isCPF === true ? 'Nome é obrigatório' : 'Razão social é obrigatório' })}
                  />
                  <S.HomeFieldLabel>
                    {isCPF === true ? 'Seu nome:' : 'Sua razão social:'}
                  </S.HomeFieldLabel>
                </S.HomeField>
                <S.HomeField>
                  <S.HomeFieldInput
                    type='text'
                    placeholder='&nbsp;'
                    {...register('mail', { required: 'E-mail é obrigatório' })}
                  />
                  <S.HomeFieldLabel>
                    Seu e-mail:
                  </S.HomeFieldLabel>
                </S.HomeField>
                <S.InvitePasswordInfoText>A senha será os 6 primeiros dígitos do seu {isCPF === true ? 'CPF' : 'CNPJ'}.</S.InvitePasswordInfoText>
                <S.InviteButton type='submit' disabled={isLoading}>
                  Cadastrar
                </S.InviteButton>
              </form>
            </S.InviteBox>
          </S.InviteInner>
        </S.InviteWrapper>
      </S.InviteContainer>
    </S.Invite>
  )
}

export default Invite
