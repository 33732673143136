import { useState, useEffect } from 'react'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import * as S from './styles'
import { useCart } from '../../../context/CartContext'
import { useOnOutsideClick } from '../../../hooks/useOnOutsideClick'
import formatMoney from '../../../util/formatMoney'
import { IMAGE_URL } from '../../../constants'
import { getGradeSelected } from '../../../util/getGradeSelected'

const Cart = ({changeHeaderColor, hasAdvancedPlan}) => {
  const { cart, deleteProduct, productAdded } = useCart()
  const navigate = useNavigate()
  const [isOpen, setIsOpen] = useState(false)
  const { innerBorderRef } = useOnOutsideClick(() => setIsOpen(false))
  const location = useLocation()

  let cartQty = 0

  if (cart) {
    cartQty = cart.items.map(item => item.quantity).reduce((quantity, acc) => {
      return quantity + acc
    }, 0)
  }

  const handleCart = () => {
    if (cart && cart.items.length > 0) {
      setIsOpen(!isOpen)
    } else {
      navigate('/cart')
    }
  }

  useEffect(() => {
    if (productAdded && location.pathname !== '/cart') {
      setIsOpen(true)
    }
  }, [productAdded])

  return (
    <S.Cart ref={innerBorderRef}>
      <S.CartMenu $changeHeaderColor={changeHeaderColor} onClick={handleCart}>
        <S.CartIcon type='cart' />
        <S.CartMenuLabel>Meu carrinho</S.CartMenuLabel>
        {cartQty > 0 && (
          <S.CartMenuQty>{cartQty}</S.CartMenuQty>
        )}
      </S.CartMenu>

      {(cart && cart.items.length > 0) && (
        <S.CartBox isOpen={isOpen}>
          <S.CartBoxNotification>
            <S.CartBoxNotificationText>
              Produto adicionado ao seu pedido!
            </S.CartBoxNotificationText>
            <S.CartBoxNotificationButton>
              <S.CartIcon type='x' />
            </S.CartBoxNotificationButton>
          </S.CartBoxNotification>
          <S.CartProducts>
            {cart && (
              cart.items.map(product => {
                const skuId = product?.productSkuDataId
                const productSku = product.product.sku
                const insideProductSku = productSku?.find((sku) => sku.id === skuId)
                const productSkuDataObj = insideProductSku?.productSkuData.find((sku) => sku.productSkuId === skuId)
                const baseSkuPrice = productSkuDataObj?.price > 0 ? productSkuDataObj.price : null 

                const productPriceTables = product.product.prices
                const priceTable = productPriceTables?.find((item) => item.skuId != null && item.skuId === skuId && item.priceTableId === cart.price )
                const priceTablePrice = priceTable?.price

                let firstImage = hasAdvancedPlan ? '/assets/images/gray-empty-image.svg' : '/logo.png'
                if (product.product.images && product.product.images.length > 0) {
                  if (product.product.images[0].includes('http')) { firstImage = product.product.images[0] } else { firstImage = `${IMAGE_URL}${product.product.images[0]}` }
                }

                let key = product.productId
                if (product.productSkuDataId) {
                  key += '_' + product.productSkuDataId
                }

                return (
                  <S.CartProduct key={key}>
                    <S.CartProductImageWrapper>
                      <S.CartProductImage
                        src={firstImage}
                        alt={product.description}
                        onError={e => { e.target.src = '/logo.png' }}
                      />
                    </S.CartProductImageWrapper>
                    <S.CartProductContent>
                      <S.CartProductTitle>
                        {product.product.sku.length > 0 ? `${getGradeSelected(product)} -` : ''} {product.product.description}
                        {product.product.multiplicationFactorValue > 1 && product.product.useMultiplicationFactor === true ?
                          <S.CartSubProductTitle>(x{product.product.multiplicationFactorValue})</S.CartSubProductTitle> : ''}
                      </S.CartProductTitle>
                      <S.CartProductWrapper>
                        <S.CartProductQty>{product.quantity}x</S.CartProductQty>
                        <S.CartProductPrice>{priceTable && priceTablePrice ? formatMoney(priceTablePrice) : skuId && baseSkuPrice ? formatMoney(baseSkuPrice) : formatMoney(product.value)}</S.CartProductPrice>
                      </S.CartProductWrapper>
                      <S.CartProductBtn onClick={() => deleteProduct(product.productId, product.productSkuDataId)}>
                        <S.CartIcon type='trash' />
                      </S.CartProductBtn>
                    </S.CartProductContent>
                  </S.CartProduct>
                )
              })
            )}
          </S.CartProducts>
          <S.CartBoxBottom>
            <S.CartSubtotal>
              <S.CartSubtotalLabel>Sub-total</S.CartSubtotalLabel>
              <S.CartSubtotalValue>{formatMoney(cart ? cart.totalValue : 0)}</S.CartSubtotalValue>
            </S.CartSubtotal>
            <Link to='/cart'>
              <S.CartButton onClick={() => setIsOpen(false)}>Revisar meu pedido</S.CartButton>
            </Link>
          </S.CartBoxBottom>
        </S.CartBox>
      )}
    </S.Cart>
  )
}

export default Cart
